<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">what we offer.</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row
                  class="service-details-content align-items-center pb--80 mb--80"
                >
                  <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        class="w-100"
                        src="../assets/images/service/dev.png"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title mb--30">
                        <h3>Web Development</h3>
                      </div>
                      <p>
                        In the fast-changing world of web everyone wants to stand out. Our goal is to help you stand out by building 
                        beautiful, fast, and modern websites and web applications that are a pleasure to use.
                      </p>
                      
                      <h4 class="title">What can you expect?</h4>
                      <ul class="liststyle bullet">
                        <li>Cutting-edge technologies and tools</li>
                        <li>Tasteful and responsive designs</li>
                        <li>Easy to navigate and use</li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <!-- Start Single Content  -->
                <v-row class="service-details-content align-items-center pb--80 mb--80">
                  <v-col lg="6" md="6" cols="12" order="2" order-md="1">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title mb--30">
                        <h3>Graphic Design</h3>
                      </div>
                      <p>
                        Having a hard time coming up with a good logo? Can't find the perfect color scheme? Want your design to communicate a message?
                        Don't worry, we're here to help!
                      </p>
                      <h4 class="title">What can you expect?</h4>
                      <ul class="liststyle bullet">
                        <li>Beautiful hand-crafted designs</li>
                        <li>Experienced designers with good taste</li>
                        <li>Logos, illustrations, and other graphics</li>
                      </ul>
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12" order="1" order-md="2">
                    <div class="thumb">
                      <img
                        class="w-100"
                        src="../assets/images/service/design.png"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <!-- Start Single Content  -->
                <v-row
                  class="service-details-content align-items-center pb--80 mb--80"
                >
                  <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        class="w-100"
                        src="../assets/images/service/creative.png"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title mb--30">
                      <h3>Branding + Marketing</h3>
                      </div>
                      <p>
                        We're here to help you market yourself in the ever-so-crowded market and drive more traffic, as you sit back and watch your business grow.
                      </p>
                      
                      <h4 class="title">What can you expect?</h4>
                      <ul class="liststyle bullet">
                        <li>Well thought-out campaigns</li>
                        <li>Dedicated person to help you run your social media</li>
                        <li>Increased customer engagement</li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <!-- Start Single Content  -->
                <v-row class="service-details-content align-items-center pb--80 mb--80">
                  <v-col lg="6" md="6" cols="12" order="2" order-md="1">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title mb--30">
                        <h3>Photo + Video</h3>
                      </div>
                      <p>
                        We can help you capture the essence of your product, brand or company and communicate it to your clients through photo and video.
                      </p>
                      <h4 class="title">What can you expect?</h4>
                      <ul class="liststyle bullet">
                        <li>Studio-grade photography</li>
                        <li>Professional videography</li>
                        <li>High-end equipment</li>
                      </ul>
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12" order="1" order-md="2">
                    <div class="thumb">
                      <img
                        class="w-100"
                        src="../assets/images/service/vision.png"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <!-- Start Single Content  -->
                <v-row
                  class="service-details-content pb--80 align-items-center"
                >
                  <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        class="w-100"
                        src="../assets/images/service/maintenance.png"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title mb--30">
                      <h3>Maintenance + Support</h3>
                      </div>
                      <p>
                        We offer a complete solution to monitor and support your website – from your domain and hosting to ensuring your site and content stay up to date. You will never have to worry about a thing.
                      </p>
                      
                      <h4 class="title">What can you expect?</h4>
                      <ul class="liststyle bullet">
                        <li>Cloud-based hosting w/ 99.9% uptime</li>
                        <li>Quick turnaround times for content updates</li>
                        <li>Domain and hosting management</li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <Footer />
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import ServiceThree from "../components/service/ServiceThree";
  import ServiceFour from "../components/service/ServiceFour";
  import ServiceTwo from "../components/service/ServiceTwo";
  import Footer from "../components/footer/Footer";
  import feather from "feather-icons";
  export default {
    components: {
      Header,
      ServiceThree,
      ServiceFour,
      ServiceTwo,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "Services",
            to: "",
            disabled: true,
          },
        ],
        serviceContent2: [
          {
            icon: "cast",
            title: "Business Stratagy",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "cast",
            title: "Website Development",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "cast",
            title: "Marketing & Reporting",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
        ],
        serviceContent: [
          {
            icon: "cast",
            title: "Business Stratagy",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "layers",
            title: "Website Development",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "users",
            title: "Marketing & Reporting",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "monitor",
            title: "Mobile Development",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "camera",
            title: "Marketing & Reporting",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "activity",
            title: "Mobile Development",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
